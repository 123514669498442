import * as React from 'react';

function TimesCircleIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M256 8a248 248 0 1 0 0 496 248 248 0 0 0 0-496zm0 448a200 200 0 1 1-.1-399.9A200 200 0 0 1 256 456zm101.8-262.2L295.6 256l62.2 62.2a12 12 0 0 1 0 17l-22.6 22.6a12 12 0 0 1-17 0L256 295.6l-62.2 62.2a12 12 0 0 1-17 0l-22.6-22.6a12 12 0 0 1 0-17l62.2-62.2-62.2-62.2a12 12 0 0 1 0-17l22.6-22.6a12 12 0 0 1 17 0l62.2 62.2 62.2-62.2a12 12 0 0 1 17 0l22.6 22.6a12 12 0 0 1 0 17z" />
    </svg>
  );
}

export default TimesCircleIcon;
