import * as React from 'react';

function MicrophoneAltIcon(props) {
  return (
    <svg {...props} viewBox="0 0 352 512">
      <path d="M336 192h-16a16 16 0 0 0-16 16v48c0 74.8-64.5 134.8-140.8 127.4C96.7 376.9 48 317 48 250.3V208a16 16 0 0 0-16-16H16a16 16 0 0 0-16 16v40.2c0 89.6 64 169.5 152 181.7V464H96a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16h-56v-33.8A176.2 176.2 0 0 0 352 256v-48a16 16 0 0 0-16-16zM176 352c53 0 96-43 96-96V96a96 96 0 1 0-192 0v160c0 53 43 96 96 96zM128 96a48 48 0 0 1 96 0h-40a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h40v32h-40a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h40v32h-40a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h40a48 48 0 0 1-96 0V96z" />
    </svg>
  );
}

export default MicrophoneAltIcon;
