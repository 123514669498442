import * as React from 'react';

function DollarSignIcon(props) {
  return (
    <svg {...props} viewBox="0 0 256 512">
      <path d="M191.9 259.3L73.7 222.2C49.2 214.5 32 189 32 160.3 32 124.8 57.6 96 89 96h73.8c22.2 0 43.3 8.6 60.1 24.5a8 8 0 0 0 11 .3l11.9-10.8a8 8 0 0 0 .4-11.6 120.6 120.6 0 0 0-83.3-34.5H144V8a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v56H89c-49.1 0-89 43.2-89 96.3 0 42.6 26.4 80.6 64.1 92.4l118.2 37.1c24.6 7.7 41.7 33.2 41.7 61.9 0 35.4-25.6 64.3-57 64.3H93.2a86.9 86.9 0 0 1-60.1-24.5 8 8 0 0 0-11-.3L10.3 402a8 8 0 0 0-.3 11.5c22.8 22 52.7 34.5 83.3 34.5H112v56a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8v-56h23c49.1 0 89-43.2 89-96.3 0-42.5-26.4-80.5-64.1-92.4z" />
    </svg>
  );
}

export default DollarSignIcon;
