import * as React from 'react';
import * as PropTypes from 'prop-types';

import './style.css';

LoadingScreen.propTypes = {
  /** Forces the loading screen to take over the entire screen. */
  fullscreen: PropTypes.bool,
  /** CSS class to add to the component's main element. */
  className: PropTypes.string
};

function LoadingScreen(props) {
  let className = 'r-loading-screen';

  if (props.fullscreen) {
    className += ' r-loading-screen--fullscreen';
  }

  if (props.className) {
    className += ` ${props.className}`;
  }

  return (
    <div className={className}>
      <svg viewBox="25 25 50 50">
        <circle cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
      </svg>
    </div>
  );
}

export default LoadingScreen;
