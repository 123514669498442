import * as React from 'react';

function ShipIcon(props) {
  return (
    <svg {...props} viewBox="0 0 640 512">
      <path d="M484.8 379.4l74.2-62.8a48 48 0 0 0-15.3-82L488 215.4V88a24 24 0 0 0-24-24h-48V24a24 24 0 0 0-24-24H248a24 24 0 0 0-24 24v40h-48a24 24 0 0 0-24 24v127.4l-55.7 19.2a48 48 0 0 0-15.3 82l74.2 62.8C131.8 430.8 84.6 464 12 464a12 12 0 0 0-12 12v24a12 12 0 0 0 12 12c61.7 0 114.3-17 157.2-66.5A80.1 80.1 0 0 0 248 512h144a80.1 80.1 0 0 0 78.8-66.5C514 495.3 566.8 512 628 512a12 12 0 0 0 12-12v-24a12 12 0 0 0-12-12c-72 0-119.5-32.7-143.2-84.6zM264 40h112v24H264V40zm-64 72h240v86.7l-104.3-36a48 48 0 0 0-31.4 0l-104.3 36V112zm224 320a32 32 0 0 1-32 32H248a32 32 0 0 1-32-32v-64l-104-88 208-72 208 72-104 88v64z" />
    </svg>
  );
}

export default ShipIcon;
