import * as React from 'react';

function WarehouseIcon(props) {
  return (
    <svg {...props} viewBox="0 0 640 512">
      <path d="M504 208H136a40 40 0 0 0-40 40v248a16 16 0 0 0 16 16h16a16 16 0 0 0 16-16v-48h352v48a16 16 0 0 0 16 16h16a16 16 0 0 0 16-16V248a40 40 0 0 0-40-40zm-8 208H144v-64h352v64zm0-96H144v-64h352v64zm101.9-209.9L346.3 5.3a68 68 0 0 0-52.6 0L42.1 110.1A68 68 0 0 0 0 173.2V496a16 16 0 0 0 16 16h16a16 16 0 0 0 16-16V173.2c0-8.3 4.9-15.7 12.5-18.8L312.2 49.6a20.4 20.4 0 0 1 15.7 0l251.6 104.8a20.4 20.4 0 0 1 12.5 18.8V496a16 16 0 0 0 16 16h16a16 16 0 0 0 16-16V173.2a68 68 0 0 0-42.1-63.1z" />
    </svg>
  );
}

export default WarehouseIcon;
