import * as React from 'react';

function DotCircleIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M256 56a199.9 199.9 0 1 1 0 400 199.9 199.9 0 1 1 0-400m0-48a248 248 0 1 0 0 496 248 248 0 0 0 0-496zm0 168a80 80 0 1 0 0 160 80 80 0 0 0 0-160z" />
    </svg>
  );
}

export default DotCircleIcon;
