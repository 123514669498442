import * as React from 'react';

function PlusCircleIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M384 240v32a12 12 0 0 1-12 12h-88v88a12 12 0 0 1-12 12h-32a12 12 0 0 1-12-12v-88h-88a12 12 0 0 1-12-12v-32a12 12 0 0 1 12-12h88v-88a12 12 0 0 1 12-12h32a12 12 0 0 1 12 12v88h88a12 12 0 0 1 12 12zm120 16a248 248 0 1 1-496 0 248 248 0 0 1 496 0zm-48 0a200 200 0 1 0-399.9-.1 200 200 0 0 0 399.9.1z" />
    </svg>
  );
}

export default PlusCircleIcon;
