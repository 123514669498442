import * as React from 'react';

function GlobeAmericasIcon(props) {
  return (
    <svg {...props} viewBox="0 0 496 512">
      <path d="M248 8a248 248 0 1 0 0 496 248 248 0 0 0 0-496zm-32 50.8v11.3a16 16 0 0 1-23.2 14.3l-24-12c14.9-6.4 30.7-11 47.2-13.6zM48 256c0-29.1 6.4-56.7 17.6-81.7 10 14.7 25.2 37.4 34.6 51.1 5.2 7.7 11.2 14.6 18 20.8l.9.7c9.5 8.6 20.2 16 31.6 21.8 14 7 34.5 18.1 48.8 26a31.9 31.9 0 0 1 16.5 28v32a32 32 0 0 0 9.4 22.7c15 15 24.3 38.6 22.6 51.2V456c-110.3 0-200-89.7-200-200zm242.7 195.3l17.3-46.8c2-5.5 3.3-11.3 4.8-17 1-4 3.2-7.6 6.2-10.6l11.3-11.3a46.5 46.5 0 0 0 13.7-33c0-8-3.2-15.8-9-21.5l-13.6-13.7a32 32 0 0 0-22.6-9.4H232c-9.4-4.7-21.5-32-32-32s-20.9-2.4-30.3-7.2l-11-5.5a12 12 0 0 1-6.7-10.7 12 12 0 0 1 8.2-11.3l31.1-10.4a16 16 0 0 1 15.6 3l9.3 8.1a8 8 0 0 0 5.2 2h5.6a8 8 0 0 0 7.2-11.6l-15.6-31.2a8 8 0 0 1 1.6-9.3l10-9.6a8 8 0 0 1 5.5-2.3h9a8 8 0 0 0 5.6-2.3l8-8a8 8 0 0 0 0-11.3l-4.6-4.7a8 8 0 0 1 0-11.3L264 112l4.7-4.7a16 16 0 0 0 0-22.6l-28.3-28.3c2.5-.1 5-.4 7.6-.4a200 200 0 0 1 178.7 110.7l-13 6.4a24 24 0 0 0-9.2 8.2l-19.6 29.4a24 24 0 0 0 0 26.6l18 27a24 24 0 0 0 14.1 10l29.2 7.2a200.3 200.3 0 0 1-155.5 169.8z" />
    </svg>
  );
}

export default GlobeAmericasIcon;
