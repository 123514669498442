import * as React from 'react';

function LinkIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M314.2 197.8c51.1 51 54.4 132.3 9.8 187.1-6.3 7.8-2.8 4-85 86A140 140 0 1 1 41 273l63.5-63.4c7.4-7.4 20.3-2.3 20.4 8.2a173.3 173.3 0 0 0 4.8 37.8c1 4-.3 8.3-3.2 11.2l-45.9 45.9a84 84 0 1 0 118.8 118.8l75.2-75.2a84.1 84.1 0 0 0 0-118.8 83.7 83.7 0 0 0-22.8-16.2 12 12 0 0 1-6.6-12.4 51.7 51.7 0 0 1 14.9-30.4l4.3-4.4a12 12 0 0 1 13.8-2.3c13 6.6 25.2 15.2 36 26zM471 41a140 140 0 0 0-198 0c-82.2 82.2-78.7 78.3-85 86a140.2 140.2 0 0 0 45.8 213.3 12 12 0 0 0 13.8-2.3l4.3-4.4a51.7 51.7 0 0 0 14.9-30.4 12 12 0 0 0-6.6-12.4 83.7 83.7 0 0 1-22.8-16.2 84.1 84.1 0 0 1 0-118.8l75.2-75.2a84 84 0 1 1 118.8 118.8l-45.9 45.9a12 12 0 0 0-3.2 11.2 173.3 173.3 0 0 1 4.8 37.8c.1 10.5 13 15.6 20.5 8.2L471 239a140 140 0 0 0 0-198z" />
    </svg>
  );
}

export default LinkIcon;
