import * as React from 'react';

function TrainIcon(props) {
  return (
    <svg {...props} viewBox="0 0 448 512">
      <path d="M448 96v256c0 51.8-61.6 96-130 96l63 49.7a8 8 0 0 1-5 14.3H72a8 8 0 0 1-5-14.3l63-49.7C61.8 448 0 404 0 352V96C0 43 64 0 128 0h192c65 0 128 43 128 96zm-48 136V120a24 24 0 0 0-24-24H72a24 24 0 0 0-24 24v112a24 24 0 0 0 24 24h304a24 24 0 0 0 24-24zm-176 64a56 56 0 1 0 0 112 56 56 0 0 0 0-112z" />
    </svg>
  );
}

export default TrainIcon;
