import * as React from 'react';

function InfoCircleIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M256 40a216 216 0 1 1 0 432 216 216 0 0 1 0-432m0-32a248 248 0 1 0 0 496 248 248 0 0 0 0-496zm-36 344h12V232h-12a12 12 0 0 1-12-12v-8a12 12 0 0 1 12-12h48a12 12 0 0 1 12 12v140h12a12 12 0 0 1 12 12v8a12 12 0 0 1-12 12h-72a12 12 0 0 1-12-12v-8a12 12 0 0 1 12-12zm36-240a32 32 0 1 0 0 64 32 32 0 0 0 0-64z" />
    </svg>
  );
}

export default InfoCircleIcon;
