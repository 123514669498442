import * as React from 'react';

function BoxesIcon(props) {
  return (
    <svg {...props} viewBox="0 0 640 512">
      <path d="M592 224H480V48a48 48 0 0 0-48-48H208a48 48 0 0 0-48 48v176H48a48 48 0 0 0-48 48v192a48 48 0 0 0 48 48h544a48 48 0 0 0 48-48V272a48 48 0 0 0-48-48zM208 48h64v90.7l48-21.3 48 21.3V48h64v176H208V48zm88 416H48V272h80v90.7l48-21.3 48 21.3V272h72v192zm296 0H344V272h72v90.7l48-21.3 48 21.3V272h80v192z" />
    </svg>
  );
}

export default BoxesIcon;
