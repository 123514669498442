import * as React from 'react';

function FileInvoiceIcon(props) {
  return (
    <svg {...props} viewBox="0 0 384 512">
      <path d="M296 400h-80a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h80a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8zM80 240v96a16 16 0 0 0 16 16h192a16 16 0 0 0 16-16v-96a16 16 0 0 0-16-16H96a16 16 0 0 0-16 16zm32 16h160v64H112v-64zM369.8 98L286 14A48 48 0 0 0 252.1 0H48C21.5.1 0 21.6 0 48v416a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V132c0-12.7-5.2-25-14.2-34zM256 52l76.1 76h-76V52zM336 464H48V48h160v104a24 24 0 0 0 24 24h104v288zM88 112h80a8 8 0 0 0 8-8V88a8 8 0 0 0-8-8H88a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8zm0 64h80a8 8 0 0 0 8-8v-16a8 8 0 0 0-8-8H88a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8z" />
    </svg>
  );
}

export default FileInvoiceIcon;
