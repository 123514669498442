import * as React from 'react';

function FileAltIcon(props) {
  return (
    <svg {...props} viewBox="0 0 384 512">
      <path d="M369.9 97.9L286 14A48 48 0 0 0 252.1-.1H48C21.5 0 0 21.5 0 48v416a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V131.9c0-12.7-5.1-25-14.1-34zm-22.6 22.7c2.1 2.1 3.5 4.6 4.2 7.4H256V32.5c2.8.7 5.3 2.1 7.4 4.2l83.9 83.9zM336 480H48a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h176v104a24 24 0 0 0 24 24h104v304a16 16 0 0 1-16 16zm-48-244v8a12 12 0 0 1-12 12H108a12 12 0 0 1-12-12v-8a12 12 0 0 1 12-12h168a12 12 0 0 1 12 12zm0 64v8a12 12 0 0 1-12 12H108a12 12 0 0 1-12-12v-8a12 12 0 0 1 12-12h168a12 12 0 0 1 12 12zm0 64v8a12 12 0 0 1-12 12H108a12 12 0 0 1-12-12v-8a12 12 0 0 1 12-12h168a12 12 0 0 1 12 12z" />
    </svg>
  );
}

export default FileAltIcon;
