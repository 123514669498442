import * as React from 'react';

function RobotIcon(props) {
  return (
    <svg {...props} viewBox="0 0 608 512">
      <path d="M208 216a40 40 0 1 0 0 80 40 40 0 0 0 0-80zm-32 192h64v-48h-64v48zm96 0h64v-48h-64v48zm288-184h-32v-32c0-53-43-96-96-96H328V24a24 24 0 1 0-48 0v72H176c-53 0-96 43-96 96v32H48a48 48 0 0 0-48 48v96a48 48 0 0 0 48 48h32v32a64 64 0 0 0 64 64h320a64 64 0 0 0 64-64v-32h32a48 48 0 0 0 48-48v-96a48 48 0 0 0-48-48zM80 368H48v-96h32v96zm400 80a16 16 0 0 1-16 16H144a16 16 0 0 1-16-16V192a48 48 0 0 1 48-48h256a48 48 0 0 1 48 48v256zm80-80h-32v-96h32v96zm-192 40h64v-48h-64v48zm32-192a40 40 0 1 0 0 80 40 40 0 0 0 0-80z" />
    </svg>
  );
}

export default RobotIcon;
