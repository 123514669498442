import * as React from 'react';

function SteeringWheelIcon(props) {
  return (
    <svg {...props} viewBox="0 0 496 512">
      <path d="M248 8a248 248 0 1 0 0 496 248 248 0 0 0 0-496zm0 48c102.1 0 186.5 77 198.4 176h-84.6a88 88 0 0 0-81.8-56h-64a88 88 0 0 0-81.8 56H49.6C61.5 133 145.9 56 248 56zm0 270l-71-71a40 40 0 0 1 39-31h64a40 40 0 0 1 39 31l-71 71zM49.6 280H134l89.9 90v84.4A200.2 200.2 0 0 1 49.6 280zM272 454.4V370l90-89.9h84.4A200.2 200.2 0 0 1 272 454.4z" />
    </svg>
  );
}

export default SteeringWheelIcon;
