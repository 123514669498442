import * as React from 'react';

function ExclamationCircleIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M256 8a248 248 0 1 0 0 496 248 248 0 0 0 0-496zm0 448a200 200 0 1 1-.1-400.1A200 200 0 0 1 256 456zm42-104a42 42 0 1 1-84 0 42 42 0 0 1 84 0zm-81.4-211.4l6.8 136a12 12 0 0 0 12 11.4h41.2a12 12 0 0 0 12-11.4l6.8-136a12 12 0 0 0-12-12.6h-54.8a12 12 0 0 0-12 12.6z" />
    </svg>
  );
}

export default ExclamationCircleIcon;
