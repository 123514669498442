import * as React from 'react';

function DownloadIcon(props) {
  return (
    <svg {...props} viewBox="0 0 576 512">
      <path d="M528 288h-92.1l46.1-46.1a48 48 0 0 0-33.9-81.9h-64V48a48 48 0 0 0-48-48h-96a48 48 0 0 0-48 48v112h-64a48 48 0 0 0-33.9 81.9l46.1 46.1H48a48 48 0 0 0-48 48v128a48 48 0 0 0 48 48h480a48 48 0 0 0 48-48V336a48 48 0 0 0-48-48zm-400-80h112V48h96v160h112L288 368 128 208zm400 256H48V336h140.1l65.9 65.9a48 48 0 0 0 67.9 0l65.9-65.9H528v128zm-88-64a24 24 0 1 1 48 0 24 24 0 1 1-48 0z" />
    </svg>
  );
}

export default DownloadIcon;
