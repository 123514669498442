import * as React from 'react';

function TimesIcon(props) {
  return (
    <svg {...props} viewBox="0 0 320 512">
      <path d="M194 256l102.5-102.6 21.1-21.1a8 8 0 0 0 0-11.3L295 98.3a8 8 0 0 0-11.3 0L160 222.1 36.3 98.3a8 8 0 0 0-11.3 0L2.3 121a8 8 0 0 0 0 11.3L126.1 256 2.3 379.7a8 8 0 0 0 0 11.3L25 413.6a8 8 0 0 0 11.3 0L160 290l102.6 102.6 21.1 21.1a8 8 0 0 0 11.3 0l22.6-22.6a8 8 0 0 0 0-11.3L194 256z" />
    </svg>
  );
}

export default TimesIcon;
