import * as React from 'react';

function PlusIcon(props) {
  return (
    <svg {...props} viewBox="0 0 384 512">
      <path d="M368 224H224V80a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v144H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h144v144a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V288h144a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16z" />
    </svg>
  );
}

export default PlusIcon;
