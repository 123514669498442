import * as React from 'react';

function TrainIcon(props) {
  return (
    <svg {...props} viewBox="0 0 448 512">
      <path d="M264 336a40 40 0 1 1-80 0 40 40 0 0 1 80 0zm184-226.3v228.6c0 49.2-43.7 90.6-99 104.7l58.7 58.8a6 6 0 0 1-4.2 10.2H355a12 12 0 0 1-8.5-3.5L286.1 448H161.9l-60.4 60.5A12 12 0 0 1 93 512H44.5a6 6 0 0 1-4.2-10.2L99.1 443C43.9 429 0 387.7 0 338.3V109.7C0 46 71 0 138.3 0h171.4C378 0 448 46 448 109.7zM48 224h352v-80H48v80zm2.8-128h346.5c-10.2-26.1-48-48-87.6-48H138.3c-38.9 0-77 21.7-87.5 48zM400 338.3V272H48v66.3c0 36.2 52 61.7 90.3 61.7h171.4c37.8 0 90.3-25.2 90.3-61.7z" />
    </svg>
  );
}

export default TrainIcon;
