import * as React from 'react';

function TruckContainerIcon(props) {
  return (
    <svg {...props} viewBox="0 0 640 512">
      <path d="M32 320h336a32 32 0 0 0 32-32V64a32 32 0 0 0-32-32H32A32 32 0 0 0 0 64v224a32 32 0 0 0 32 32zm0-256h336v224H32V64zm589.3 141.3l-58.5-58.5a64 64 0 0 0-45.3-18.7H448a16 16 0 0 0-16 16v208H228.7A71 71 0 0 0 128 363.4 71.3 71.3 0 0 0 72 336a72 72 0 1 0 56 116.7 71.5 71.5 0 0 0 56 27.3 72 72 0 0 0 67.6-96h198a80.7 80.7 0 0 0 2.8 42.4 80.2 80.2 0 1 0 154-42.5H624a16 16 0 0 0 16-16V250.5c0-17-6.7-33.2-18.7-45.2zM72 448a40 40 0 1 1 0-80 40 40 0 0 1 0 80zm112 0a40 40 0 1 1 0-80 40 40 0 0 1 0 80zm280-288h53.5c8.5 0 16.6 3.3 22.6 9.4l54.6 54.6H464v-64zm64 288a48 48 0 1 1 0-96 48 48 0 0 1 0 96zm80-96h-16.4c-14.6-19.3-37.5-32-63.6-32s-49 12.7-63.6 32h-.4v-96h144v96zm-504-96h16a8 8 0 0 0 8-8V104a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v144a8 8 0 0 0 8 8zm96 0h16a8 8 0 0 0 8-8V104a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v144a8 8 0 0 0 8 8zm96 0h16a8 8 0 0 0 8-8V104a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v144a8 8 0 0 0 8 8z" />
    </svg>
  );
}

export default TruckContainerIcon;
