import * as React from 'react';

function FileAltIcon(props) {
  return (
    <svg {...props} viewBox="0 0 384 512">
      <path d="M288 248v28a12 12 0 0 1-12 12H108a12 12 0 0 1-12-12v-28a12 12 0 0 1 12-12h168a12 12 0 0 1 12 12zm-12 72H108a12 12 0 0 0-12 12v28a12 12 0 0 0 12 12h168a12 12 0 0 0 12-12v-28a12 12 0 0 0-12-12zm108-188.1V464a48 48 0 0 1-48 48H48a48 48 0 0 1-48-48V48A48 48 0 0 1 48 0h204.1A48 48 0 0 1 286 14.1L369.9 98c9 8.9 14.1 21.2 14.1 33.9zm-128-80V128h76.1L256 51.9zM336 464V176H232a24 24 0 0 1-24-24V48H48v416h288z" />
    </svg>
  );
}

export default FileAltIcon;
