import * as React from 'react';

function CheckCircleIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M256 8a248 248 0 1 0 0 496 248 248 0 0 0 0-496zm0 48a199.9 199.9 0 1 1 0 400 199.9 199.9 0 1 1 0-400m140.2 130.3l-22.5-22.8a12 12 0 0 0-17 0L215.3 303.7l-59.7-60.3a12 12 0 0 0-17 0l-22.7 22.5a12 12 0 0 0-.1 17l90.8 91.5a12 12 0 0 0 17 0L396 203.2a12 12 0 0 0 .1-17z" />
    </svg>
  );
}

export default CheckCircleIcon;
