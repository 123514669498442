import * as React from 'react';

function AngleUpIcon(props) {
  return (
    <svg {...props} viewBox="0 0 320 512">
      <path d="M168.5 164.2l148 146.8a12 12 0 0 1 0 17l-19.8 19.8a12 12 0 0 1-17 0L160 229.3 40.3 347.8a12 12 0 0 1-17 0L3.5 328a12 12 0 0 1 0-17l148-146.8a12 12 0 0 1 17 0z" />
    </svg>
  );
}

export default AngleUpIcon;
