import * as React from 'react';

function ExclamationCircleIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M504 256a248 248 0 1 1-496 0 248 248 0 0 1 496 0zm-248 50a46 46 0 1 0 0 92 46 46 0 0 0 0-92zm-43.7-165.3l7.4 136a12 12 0 0 0 12 11.3h48.6a12 12 0 0 0 12-11.3l7.4-136a12 12 0 0 0-12-12.7h-63.4a12 12 0 0 0-12 12.7z" />
    </svg>
  );
}

export default ExclamationCircleIcon;
