import * as React from 'react';

function CaretDownIcon(props) {
  return (
    <svg {...props} viewBox="0 0 320 512">
      <path d="M31.3 192h257.3a20 20 0 0 1 14.1 34.1L174.1 354.8a20 20 0 0 1-28.3 0L17.2 226.1A20 20 0 0 1 31.3 192z" />
    </svg>
  );
}

export default CaretDownIcon;
