import * as React from 'react';

function SyncIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M500 8h-27.7a12 12 0 0 0-12 12.3l2.3 98.6a248 248 0 0 0-450.3 91A12 12 0 0 0 24.1 224h28.6a12 12 0 0 0 11.7-9.5 196 196 0 0 1 370.3-39.2l-126.4-3a12 12 0 0 0-12.3 12V212a12 12 0 0 0 12 12h192a12 12 0 0 0 12-12V20a12 12 0 0 0-12-12zm-12.1 280h-28.6a12 12 0 0 0-11.7 9.5 196 196 0 0 1-370.3 39.2l126.4 3a12 12 0 0 0 12.3-12V300a12 12 0 0 0-12-12H12a12 12 0 0 0-12 12v192a12 12 0 0 0 12 12h27.7a12 12 0 0 0 12-12.3l-2.3-98.6a248 248 0 0 0 450.3-91 12 12 0 0 0-11.8-14.1z" />
    </svg>
  );
}

export default SyncIcon;
