import * as React from 'react';

function AngleDownIcon(props) {
  return (
    <svg {...props} viewBox="0 0 320 512">
      <path d="M151.5 347.8L3.5 201a12 12 0 0 1 0-17l19.8-19.8a12 12 0 0 1 17 0L160 282.7l119.7-118.5a12 12 0 0 1 17 0l19.8 19.8a12 12 0 0 1 0 17l-148 146.8a12 12 0 0 1-17 0z" />
    </svg>
  );
}

export default AngleDownIcon;
