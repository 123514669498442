import * as React from 'react';

function CommentAltIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M448 0H64A64 64 0 0 0 0 64v288a64 64 0 0 0 64 64h96v84a12 12 0 0 0 19.1 9.6L304 416h144a64 64 0 0 0 64-64V64a64 64 0 0 0-64-64zm16 352a16 16 0 0 1-16 16H288l-12.8 9.6L208 428v-60H64a16 16 0 0 1-16-16V64a16 16 0 0 1 16-16h384a16 16 0 0 1 16 16v288z" />
    </svg>
  );
}

export default CommentAltIcon;
