import * as React from 'react';

function BalanceScaleIcon(props) {
  return (
    <svg {...props} viewBox="0 0 640 512">
      <path d="M256 336c0-16.2 1.3-8.7-85-181.5a47.5 47.5 0 0 0-43-26.5c-17 0-34.1 8.8-43 26.5C-2 328.8 0 320.3 0 336c0 44.2 57.3 80 128 80s128-35.8 128-80zM83.2 265.1c11.4-22.6 26-51.7 44.5-89l.3-.1 46.7 93c11.3 22.3 19.7 39 25.6 51H55.8c6.2-12.7 15.3-30.7 27.4-54.9zM528 464H344V156a79.8 79.8 0 0 0 54.4-60H528a16 16 0 0 0 16-16V64a16 16 0 0 0-16-16H393.2a80 80 0 0 0-146.4 0H112a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h129.6a79.8 79.8 0 0 0 54.4 60v308H112a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16zM320 112a32 32 0 1 1 0-64 32 32 0 0 1 0 64zm320 224c0-16.2 1.3-8.7-85-181.5a47.5 47.5 0 0 0-43-26.5c-17 0-34.1 8.8-43 26.5-87 174.3-85 165.8-85 181.5 0 44.2 57.3 80 128 80s128-35.8 128-80zm-200.2-16c6.2-12.7 15.3-30.7 27.4-54.9 11.4-22.6 26-51.7 44.5-89l.3-.1 46.7 93c11.3 22.3 19.7 39 25.6 51H439.8z" />
    </svg>
  );
}

export default BalanceScaleIcon;
