import * as React from 'react';

function TrashAltIcon(props) {
  return (
    <svg {...props} viewBox="0 0 448 512">
      <path d="M336 64l-33.6-44.8A48 48 0 0 0 264 0h-80a48 48 0 0 0-38.4 19.2L112 64H24A24 24 0 0 0 0 88v2a6 6 0 0 0 6 6h26v368a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V96h26a6 6 0 0 0 6-6v-2a24 24 0 0 0-24-24h-88zM184 32h80c5 0 9.8 2.4 12.8 6.4L296 64H152l19.2-25.6c3-4 7.8-6.4 12.8-6.4zm200 432a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16V96h320v368zm-176-44V156a12 12 0 0 1 12-12h8a12 12 0 0 1 12 12v264a12 12 0 0 1-12 12h-8a12 12 0 0 1-12-12zm-80 0V156a12 12 0 0 1 12-12h8a12 12 0 0 1 12 12v264a12 12 0 0 1-12 12h-8a12 12 0 0 1-12-12zm160 0V156a12 12 0 0 1 12-12h8a12 12 0 0 1 12 12v264a12 12 0 0 1-12 12h-8a12 12 0 0 1-12-12z" />
    </svg>
  );
}

export default TrashAltIcon;
