import * as React from 'react';

function ListIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M500 124H140a12 12 0 0 1-12-12V80a12 12 0 0 1 12-12h360a12 12 0 0 1 12 12v32a12 12 0 0 1-12 12zm12 148v-32a12 12 0 0 0-12-12H140a12 12 0 0 0-12 12v32a12 12 0 0 0 12 12h360a12 12 0 0 0 12-12zm0 160v-32a12 12 0 0 0-12-12H140a12 12 0 0 0-12 12v32a12 12 0 0 0 12 12h360a12 12 0 0 0 12-12zM92 128V64a12 12 0 0 0-12-12H16A12 12 0 0 0 4 64v64a12 12 0 0 0 12 12h64a12 12 0 0 0 12-12zm0 160v-64a12 12 0 0 0-12-12H16a12 12 0 0 0-12 12v64a12 12 0 0 0 12 12h64a12 12 0 0 0 12-12zm0 160v-64a12 12 0 0 0-12-12H16a12 12 0 0 0-12 12v64a12 12 0 0 0 12 12h64a12 12 0 0 0 12-12z" />
    </svg>
  );
}

export default ListIcon;
