import * as React from 'react';

function ClockIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M256 8a248 248 0 1 0 0 496 248 248 0 0 0 0-496zm0 448a200 200 0 1 1-.1-399.9A200 200 0 0 1 256 456zm61.8-104.4l-84.9-61.7a12 12 0 0 1-4.9-9.7V116a12 12 0 0 1 12-12h32a12 12 0 0 1 12 12v141.7l66.8 48.6a12 12 0 0 1 2.6 16.8L334.6 349a12 12 0 0 1-16.8 2.6z" />
    </svg>
  );
}

export default ClockIcon;
