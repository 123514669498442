import * as React from 'react';

function ParkingIcon(props) {
  return (
    <svg {...props} viewBox="0 0 448 512">
      <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zm16 400a16 16 0 0 1-16 16H48a16 16 0 0 1-16-16V80a16 16 0 0 1 16-16h352a16 16 0 0 1 16 16v352zM244.1 127.9H152a8 8 0 0 0-8 8v240a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8v-72h72a88.1 88.1 0 0 0 87.6-96.8c-4.4-45.7-45.5-79.2-91.5-79.2zM248 272h-72V160h72a56 56 0 0 1 0 112z" />
    </svg>
  );
}

export default ParkingIcon;
