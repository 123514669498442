import * as React from 'react';

function UploadIcon(props) {
  return (
    <svg {...props} viewBox="0 0 576 512">
      <path d="M528 288H384v-32h64a48 48 0 0 0 33.9-81.9l-160-160a48 48 0 0 0-67.9 0l-160 160a48 48 0 0 0 34 81.9h64v32H48a48 48 0 0 0-48 48v128a48 48 0 0 0 48 48h480a48 48 0 0 0 48-48V336a48 48 0 0 0-48-48zm-400-80L288 48l160 160H336v160h-96V208H128zm400 256H48V336h144v32a48 48 0 0 0 48 48h96a48 48 0 0 0 48-48v-32h144v128zm-40-64a24 24 0 1 1-48 0 24 24 0 1 1 48 0z" />
    </svg>
  );
}

export default UploadIcon;
