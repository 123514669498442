import * as React from 'react';

function WarehouseIcon(props) {
  return (
    <svg {...props} viewBox="0 0 640 512">
      <path d="M512 224H128a32 32 0 0 0-32 32v248a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8v-56h384v56a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8V256a32 32 0 0 0-32-32zm0 192H128v-64h384v64zm0-96H128v-64h384v64zm98.6-201.7L338.6 3.7a48 48 0 0 0-37.2 0l-272 114.6A47.8 47.8 0 0 0 0 162.5V504a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8V162.5a16 16 0 0 1 9.8-14.8l272-114.6c3.9-1.7 8.5-1.7 12.4 0l272 114.6a16 16 0 0 1 9.8 14.8V504a8 8 0 0 0 8 8h16a8 8 0 0 0 8-8V162.5a47.8 47.8 0 0 0-29.4-44.2z" />
    </svg>
  );
}

export default WarehouseIcon;
