import * as React from 'react';

function WarehouseIcon(props) {
  return (
    <svg {...props} viewBox="0 0 640 512">
      <path d="M504 352H136.4a8 8 0 0 0-8 8l-.1 48a8 8 0 0 0 8 8H504a8 8 0 0 0 8-8v-48a8 8 0 0 0-8-8zm0 96H136.1a8 8 0 0 0-8 8l-.1 48a8 8 0 0 0 8 8h368a8 8 0 0 0 8-8v-48a8 8 0 0 0-8-8zm0-192H136.6a8 8 0 0 0-8 8l-.1 48a8 8 0 0 0 8 8H504a8 8 0 0 0 8-8v-48a8 8 0 0 0-8-8zm106.5-139L338.4 3.7a48.1 48.1 0 0 0-36.9 0L29.5 117A48.1 48.1 0 0 0 0 161.3V504a8 8 0 0 0 8 8h80a8 8 0 0 0 8-8V256c0-17.6 14.6-32 32.6-32h382.8c18 0 32.6 14.4 32.6 32v248a8 8 0 0 0 8 8h80a8 8 0 0 0 8-8V161.3a48.1 48.1 0 0 0-29.5-44.3z" />
    </svg>
  );
}

export default WarehouseIcon;
