import * as React from 'react';
import * as PropTypes from 'prop-types';

import './style.css';
import LoadingSpinner from '../LoadingSpinner';

export default class Button extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary']),
    disabled: PropTypes.bool,
    icon: PropTypes.node,
    label: PropTypes.string,
    layoutDirection: PropTypes.oneOf(['rtl']),
    async: PropTypes.bool, // button will show spinner as soon as it is clicked
    isLoading: PropTypes.bool, // controls when to hide the spinner and reenable the button
    variant: PropTypes.oneOf(['outlined', 'text']),
    type: PropTypes.oneOf(['button', 'submit']),
    onClick: PropTypes.func
  };

  state = {
    isLoading: this.props.isLoading
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.state.isLoading) {
      this.state.isLoading = nextProps.isLoading;
    }
  }

  handleClick = (event) => {
    if (this.props.async) {
      this.setState(
        () => {
          return { isLoading: true };
        },
        () => {
          if (this.props.onClick) {
            this.props.onClick(event, this.props);
          }
        }
      );
    } else if (this.props.onClick) {
      this.props.onClick(event, this.props);
    }
  };

  render() {
    let className = 'r-button';

    if (this.props.layoutDirection === 'rtl') {
      className += ' r-button-layout--rtl';
    }

    if (this.props.variant) {
      className += ` r-button-type-${this.props.variant}`;

      if (this.props.disabled || this.state.isLoading) {
        className += ` r-button-type-${this.props.variant}--disabled`;
      } else if (this.props.color) {
        className += ` r-button-type-${this.props.variant}--${this.props.color}`;
      }
    } else if (this.props.disabled || this.state.isLoading) {
      className += ' r-button--disabled';
    } else if (this.props.color) {
      className += ` r-button--${this.props.color}`;
    }

    if (this.props.className) {
      className += ` ${this.props.className}`;
    }

    const icon = this.state.isLoading ? <LoadingSpinner /> : this.props.icon;

    return (
      // eslint-disable-next-line react/button-has-type
      <button
        type={this.props.type || 'button'}
        className={className}
        disabled={this.props.disabled || this.state.isLoading}
        onClick={this.handleClick}
      >
        {icon}
        {this.props.label && icon && <div className="r-button__icon-spacer" />}
        {this.props.label}
      </button>
    );
  }
}
