import * as React from 'react';

function BookIcon(props) {
  return (
    <svg {...props} viewBox="0 0 448 512">
      <path d="M128 152v-32a8 8 0 0 1 8-8h208a8 8 0 0 1 8 8v32a8 8 0 0 1-8 8H136a8 8 0 0 1-8-8zm8 88h208a8 8 0 0 0 8-8v-32a8 8 0 0 0-8-8H136a8 8 0 0 0-8 8v32a8 8 0 0 0 8 8zm299.1 159.7c-4.2 13-4.2 51.6 0 64.6A16 16 0 0 1 448 480v16a16 16 0 0 1-16 16H80a80 80 0 0 1-80-80V80A80 80 0 0 1 80 0h352a16 16 0 0 1 16 16v368a16 16 0 0 1-12.9 15.7zm-41.1.3H80a32 32 0 1 0 0 64h314a253.9 253.9 0 0 1 0-64zm6-352H80a32 32 0 0 0-32 32v278.7a79.4 79.4 0 0 1 32-6.7h320V48z" />
    </svg>
  );
}

export default BookIcon;
