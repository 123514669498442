import * as React from 'react';

function InfoCircleIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M256 8a248 248 0 1 0 0 496 248 248 0 0 0 0-496zm0 110a42 42 0 1 1 0 84 42 42 0 0 1 0-84zm56 254a12 12 0 0 1-12 12h-88a12 12 0 0 1-12-12v-24a12 12 0 0 1 12-12h12v-64h-12a12 12 0 0 1-12-12v-24a12 12 0 0 1 12-12h64a12 12 0 0 1 12 12v100h12a12 12 0 0 1 12 12v24z" />
    </svg>
  );
}

export default InfoCircleIcon;
