import * as React from 'react';

function ExclamationTriangleIcon(props) {
  return (
    <svg {...props} viewBox="0 0 576 512">
      <path d="M569.5 440a48 48 0 0 1-41.6 72H48.1c-37 0-60-40-41.6-72l240-416a48 48 0 0 1 83 0l240 416zM288 354a46 46 0 1 0 0 92 46 46 0 0 0 0-92zm-43.7-165.3l7.4 136a12 12 0 0 0 12 11.3h48.6a12 12 0 0 0 12-11.3l7.4-136a12 12 0 0 0-12-12.7h-63.4a12 12 0 0 0-12 12.7z" />
    </svg>
  );
}

export default ExclamationTriangleIcon;
