import * as React from 'react';

function MoneyBillAltIcon(props) {
  return (
    <svg {...props} viewBox="0 0 640 512">
      <path d="M320 144c-53 0-96 50.1-96 112s43 112 96 112 96-50.1 96-112-43-112-96-112zm40 168a8 8 0 0 1-8 8h-64a8 8 0 0 1-8-8v-16a8 8 0 0 1 8-8h16v-55.4l-.5.3a8 8 0 0 1-11-2.3l-9-13.3a8 8 0 0 1 2.3-11L301 196a24 24 0 0 1 13.3-4H328a8 8 0 0 1 8 8v88h16a8 8 0 0 1 8 8v16zM608 64H32A32 32 0 0 0 0 96v320a32 32 0 0 0 32 32h576a32 32 0 0 0 32-32V96a32 32 0 0 0-32-32zm-16 272a64 64 0 0 0-64 64H112a64 64 0 0 0-64-64V176a64 64 0 0 0 64-64h416a64 64 0 0 0 64 64v160z" />
    </svg>
  );
}

export default MoneyBillAltIcon;
