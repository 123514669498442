import * as React from 'react';

function UserFriendsIcon(props) {
  return (
    <svg {...props} viewBox="0 0 640 512">
      <path d="M480 256a96 96 0 1 0 0-192 96 96 0 0 0 0 192zm0-160a64 64 0 1 1-.1 128.1A64 64 0 0 1 480 96zM192 256a112 112 0 1 0 .1-223.9A112 112 0 0 0 192 256zm0-192a80.1 80.1 0 0 1 0 160 80.1 80.1 0 0 1 0-160zm80.1 212c-33.4 0-41.7 12-80.1 12-38.4 0-46.7-12-80.1-12-36.3 0-71.6 16.2-92.3 46.9A114.6 114.6 0 0 0 0 387.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-44.8c0-23.8-7.2-45.9-19.6-64.3a110.2 110.2 0 0 0-92.3-46.9zM352 432a16 16 0 0 1-16 16H48a16 16 0 0 1-16-16v-44.8a83 83 0 0 1 14.1-46.4 78.3 78.3 0 0 1 65.7-32.8c27.4 0 37.2 12 80.2 12s52.8-12 80.1-12a78.3 78.3 0 0 1 65.7 32.8 83 83 0 0 1 14.1 46.4V432zm271.7-114.9a92 92 0 0 0-76.9-39.1c-27.8 0-34.8 10-66.8 10s-39-10-66.8-10c-13.2 0-26.1 3-38.1 8.1 15.2 15.4 18.5 23.6 20.2 26.6a65.6 65.6 0 0 1 17.9-2.6c21.8 0 30 10 66.8 10s45-10 66.8-10a60 60 0 0 1 50.4 25 63.6 63.6 0 0 1 10.9 35.7V408a8 8 0 0 1-8 8H416c0 17.7.3 22.5-1.6 32H600a40 40 0 0 0 40-40v-37.3c0-19.9-6-38.3-16.3-53.6z" />
    </svg>
  );
}

export default UserFriendsIcon;
