import * as React from 'react';

function ParkingIcon(props) {
  return (
    <svg {...props} viewBox="0 0 448 512">
      <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zM240 320h-48v48a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16V144a16 16 0 0 1 16-16h96c52.9 0 96 43.1 96 96s-43.1 96-96 96zm0-128h-48v64h48c17.6 0 32-14.4 32-32s-14.4-32-32-32z" />
    </svg>
  );
}

export default ParkingIcon;
