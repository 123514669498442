import * as React from 'react';

function UndoIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M12 8h27.7a12 12 0 0 1 12 12.3l-2.3 98.6A247.7 247.7 0 0 1 256.8 8a248 248 0 1 1-167 432 12 12 0 0 1-.5-17.3l19.8-19.8a12 12 0 0 1 16.5-.5A195.9 195.9 0 1 0 256 60 196 196 0 0 0 77.3 175.3l126.4-3a12 12 0 0 1 12.3 12V212a12 12 0 0 1-12 12H12a12 12 0 0 1-12-12V20A12 12 0 0 1 12 8z" />
    </svg>
  );
}

export default UndoIcon;
