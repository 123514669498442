import * as React from 'react';

function CaretUpIcon(props) {
  return (
    <svg {...props} viewBox="0 0 320 512">
      <path d="M288.7 352H31.3a20 20 0 0 1-14.1-34.1l128.7-128.7a20 20 0 0 1 28.2 0l128.7 128.7a20 20 0 0 1-14.1 34.1z" />
    </svg>
  );
}

export default CaretUpIcon;
