import * as React from 'react';

function CheckCircleIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M504 256a248 248 0 1 1-496 0 248 248 0 0 1 496 0zM227.3 387.3l184-184a16 16 0 0 0 0-22.6L388.7 158a16 16 0 0 0-22.6 0L216 308l-70-70a16 16 0 0 0-22.7 0l-22.6 22.6a16 16 0 0 0 0 22.6l104 104a16 16 0 0 0 22.6 0z" />
    </svg>
  );
}

export default CheckCircleIcon;
