import * as React from 'react';

function ShipIcon(props) {
  return (
    <svg {...props} viewBox="0 0 640 512">
      <path d="M480 366l85.2-78a32 32 0 0 0-8.6-53.4L480 201.8V80a16 16 0 0 0-16-16h-48V24a24 24 0 0 0-24-24H248a24 24 0 0 0-24 24v40h-48a16 16 0 0 0-16 16v121.8l-76 32.6a32 32 0 0 0-9.6 53.2L160 366C160 402 109 480 12 480a12 12 0 0 0-12 12v8a12 12 0 0 0 12 12 184 184 0 0 0 150.8-73.1A96.2 96.2 0 0 0 256 512h128c45 0 83-31.2 93.2-73.1C511 483.3 562.8 512 628 512a12 12 0 0 0 12-12v-8a12 12 0 0 0-12-12c-93.6 0-148-74.8-148-114zM256 32h128v32H256V32zm-64 64h256v92l-115.4-49.4a32 32 0 0 0-25.2 0L192 188V96zm256 256v64a64 64 0 0 1-64 64H256a64 64 0 0 1-64-64v-64l-96-88 224-96 224 96-96 88z" />
    </svg>
  );
}

export default ShipIcon;
