import * as React from 'react';

function UserIcon(props) {
  return (
    <svg {...props} viewBox="0 0 448 512">
      <path d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16A134.4 134.4 0 0 0 0 438.4V464a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6a86.6 86.6 0 0 1 86.4-86.4c14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16a86.6 86.6 0 0 1 86.4 86.4V464zM224 288a144 144 0 1 0 0-288 144 144 0 0 0 0 288zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z" />
    </svg>
  );
}

export default UserIcon;
