import * as React from 'react';

function TruckRampIcon(props) {
  return (
    <svg {...props} viewBox="0 0 640 512">
      <path d="M544 48h96V0H384a32 32 0 0 0-32 32v340.6L5.9 465.4a8 8 0 0 0-5.7 9.8l8.3 30.9a8 8 0 0 0 9.8 5.7L416.5 405a111.8 111.8 0 0 0 223.5-5 111.7 111.7 0 1 0-212.8-48H400V48h144zm-16 288a64 64 0 1 1-.1 128.1A64 64 0 0 1 528 336z" />
    </svg>
  );
}

export default TruckRampIcon;
