import * as React from 'react';

function FileContractIcon(props) {
  return (
    <svg {...props} viewBox="0 0 384 512">
      <path d="M196.7 363.3l-14-41.6a24 24 0 0 0-45.5 0L119 376.4a11.1 11.1 0 0 1-10.6 7.6H96a16 16 0 1 0 0 32h12.4c18.6 0 35.1-11.9 41-29.5l10.6-32 16.8 50.6a16 16 0 0 0 14 10.8l1.2.1a16 16 0 0 0 14.3-8.9l7.7-15.3c2.7-5.6 7.9-6.2 10-6.2s7.2.6 10.2 6.5a43 43 0 0 0 38.6 23.9H288a16 16 0 1 0 0-32h-15.2c-4.3 0-8.1-2.4-10.2-6.5a44.9 44.9 0 0 0-66-14.2zM72 96h112a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8H72a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8zm120 56v-16a8 8 0 0 0-8-8H72a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h112a8 8 0 0 0 8-8zm177.9-54L286 14A48 48 0 0 0 252.1 0H48C21.5.1 0 21.6 0 48v416a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V132c0-12.7-5.1-25-14.1-34zM256 32.6c2.8.7 5.3 2 7.4 4.2l84 83.9c2 2 3.4 4.6 4.1 7.4H256V32.6zM352 464a16 16 0 0 1-16 16H48a16 16 0 0 1-16-16V48c0-8.7 7.2-16 16-16h176v104c0 13.4 10.7 24 24 24h104v304z" />
    </svg>
  );
}

export default FileContractIcon;
