import * as React from 'react';

function MoneyBillIcon(props) {
  return (
    <svg {...props} viewBox="0 0 640 512">
      <path d="M608 64H32A32 32 0 0 0 0 96v320a32 32 0 0 0 32 32h576a32 32 0 0 0 32-32V96a32 32 0 0 0-32-32zm-16 272a64 64 0 0 0-64 64H112a64 64 0 0 0-64-64V176a64 64 0 0 0 64-64h416a64 64 0 0 0 64 64v160zM320 160c-44.2 0-80 43-80 96s35.8 96 80 96 80-43 80-96-35.8-96-80-96z" />
    </svg>
  );
}

export default MoneyBillIcon;
