import * as React from 'react';

function ExclamationSquareIcon(props) {
  return (
    <svg {...props} viewBox="0 0 448 512">
      <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zm16 400a16 16 0 0 1-16 16H48a16 16 0 0 1-16-16V80a16 16 0 0 1 16-16h352a16 16 0 0 1 16 16v352zm-192-92a28 28 0 1 0 0 56 28 28 0 0 0 0-56zm-11.5-212h23a12 12 0 0 1 12 12.5l-7 168a12 12 0 0 1-12 11.5h-9a12 12 0 0 1-12-11.5l-7-168a12 12 0 0 1 12-12.5zM224 340a28 28 0 1 0 0 56 28 28 0 0 0 0-56z" />
    </svg>
  );
}

export default ExclamationSquareIcon;
