import * as React from 'react';

function PhoneIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M476.5 22.9L382.3 1.2A45.6 45.6 0 0 0 330 27.8l-43.5 101.5a45.9 45.9 0 0 0 13.1 53.4l40 32.7a311 311 0 0 1-124.2 124.1l-32.7-40a45.9 45.9 0 0 0-53.4-13.1L27.7 329.9a45.7 45.7 0 0 0-26.6 52.3l21.7 94.2a45.6 45.6 0 0 0 44.6 35.5A444.4 444.4 0 0 0 512 67.5a45.6 45.6 0 0 0-35.5-44.6zM69.3 464l-20.9-90.7 98.2-42.1 55.7 68.1c98.8-46.4 150.6-98 197-197l-68.1-55.7 42.1-98.2L464 69.3A396.5 396.5 0 0 1 69.3 464z" />
    </svg>
  );
}

export default PhoneIcon;
