import * as React from 'react';

function BoxIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M509.5 184.6L458.9 32.8A47.9 47.9 0 0 0 413.4 0H98.6c-20.7 0-39 13.2-45.5 32.8L2.5 184.6A49 49 0 0 0 0 199.8V464a48 48 0 0 0 48 48h416a48 48 0 0 0 48-48V199.8c0-5.2-.8-10.3-2.5-15.2zm-48.1 7.4H280V48h133.4l48 144zM98.6 48H232v144H50.6l48-144zM48 464V240h416v224H48z" />
    </svg>
  );
}

export default BoxIcon;
