import * as React from 'react';

function FileExportIcon(props) {
  return (
    <svg {...props} viewBox="0 0 576 512">
      <path d="M572.3 279l-71.8-72.3a12 12 0 0 0-20.5 8.5v48.9h-96V132c0-12.7-5.2-25-14.2-34L286 14A48 48 0 0 0 252.1 0H48C21.5.1 0 21.6 0 48v416a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V360h-48v104H48V48h160v104a24 24 0 0 0 24 24h104v88H172a12 12 0 0 0-12 12v24a12 12 0 0 0 12 12h308v49a12 12 0 0 0 20.5 8.4l71.8-72.3c5-5 5-13 0-18zM256 128V52l76 76h-76z" />
    </svg>
  );
}

export default FileExportIcon;
