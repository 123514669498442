import * as React from 'react';

function TimesSquareIcon(props) {
  return (
    <svg {...props} viewBox="0 0 448 512">
      <path d="M325.8 193.8L263.6 256l62.2 62.2a12 12 0 0 1 0 17l-22.6 22.6a12 12 0 0 1-17 0L224 295.6l-62.2 62.2a12 12 0 0 1-17 0l-22.6-22.6a12 12 0 0 1 0-17l62.2-62.2-62.2-62.2a12 12 0 0 1 0-17l22.6-22.6a12 12 0 0 1 17 0l62.2 62.2 62.2-62.2a12 12 0 0 1 17 0l22.6 22.6a12 12 0 0 1 0 17zM448 80v352a48 48 0 0 1-48 48H48a48 48 0 0 1-48-48V80a48 48 0 0 1 48-48h352a48 48 0 0 1 48 48zm-48 346V86a6 6 0 0 0-6-6H54a6 6 0 0 0-6 6v340a6 6 0 0 0 6 6h340a6 6 0 0 0 6-6z" />
    </svg>
  );
}

export default TimesSquareIcon;
