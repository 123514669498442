import * as React from 'react';

function EllipsisVIcon(props) {
  return (
    <svg {...props} viewBox="0 0 128 512">
      <path d="M64 208a48 48 0 1 1 0 96 48 48 0 0 1 0-96zM16 104a48 48 0 1 0 96 0 48 48 0 0 0-96 0zm0 304a48 48 0 1 0 96 0 48 48 0 0 0-96 0z" />
    </svg>
  );
}

export default EllipsisVIcon;
