import * as React from 'react';

function TimesIcon(props) {
  return (
    <svg {...props} viewBox="0 0 320 512">
      <path d="M207.6 256l107.7-107.7a16 16 0 0 0 0-22.6l-25-25a16 16 0 0 0-22.6 0L160 208.4 52.3 100.7a16 16 0 0 0-22.6 0l-25 25a16 16 0 0 0 0 22.6L112.4 256 4.7 363.7a16 16 0 0 0 0 22.6l25 25a16 16 0 0 0 22.6 0L160 303.6l107.7 107.7a16 16 0 0 0 22.6 0l25-25a16 16 0 0 0 0-22.6L207.6 256z" />
    </svg>
  );
}

export default TimesIcon;
