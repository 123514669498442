import * as React from 'react';

function FilePdfIcon(props) {
  return (
    <svg {...props} viewBox="0 0 384 512">
      <path d="M369.9 97.9L286 14A48 48 0 0 0 252.1-.1H48C21.5 0 0 21.5 0 48v416a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V131.9c0-12.7-5.1-25-14.1-34zm-22.6 22.7c2.1 2.1 3.5 4.6 4.2 7.4H256V32.5c2.8.7 5.3 2.1 7.4 4.2l83.9 83.9zM336 480H48a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16h176v104a24 24 0 0 0 24 24h104v304a16 16 0 0 1-16 16zm-22-171.2c-13.5-13.3-55-9.2-73.7-6.7a104.7 104.7 0 0 1-45.1-56.6c4.3-18 12-47.2 6.4-64.9-4.4-28.1-39.7-24.7-44.6-6.8-5 18.3-.3 44.4 8.4 77.8a962.7 962.7 0 0 1-42.1 88.6c-20.8 10.7-54.1 29.3-58.8 52.4-3.5 16.8 22.9 39.4 53.1 6.4 9.1-9.9 19.3-24.8 31.3-45.5 26.7-8.8 56.1-19.8 82-24 21.9 12 47.6 19.9 64.6 19.9 27.7.1 28.9-30.2 18.5-40.6zm-229.2 89c5.9-15.9 28.6-34.4 35.5-40.8-22.1 35.3-35.5 41.5-35.5 40.8zM180 175.5c8.7 0 7.8 37.5 2.1 47.6-5.2-16.3-5-47.6-2.1-47.6zm-28.4 159.3a469 469 0 0 0 28.8-63.7 130.3 130.3 0 0 0 35.1 41.5c-24.3 4.7-45.4 15.1-63.9 22.2zm153.4-5.9s-5.8 7-43.5-9.1c41-3 47.7 6.4 43.5 9.1z" />
    </svg>
  );
}

export default FilePdfIcon;
