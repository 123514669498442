import * as React from 'react';

function LightbulbIcon(props) {
  return (
    <svg {...props} viewBox="0 0 384 512">
      <path d="M272 428v28a24 24 0 0 1-16 22.6v9.4a24 24 0 0 1-24 24h-80a24 24 0 0 1-24-24v-9.4a24 24 0 0 1-16-22.6v-28a12 12 0 0 1 12-12h136a12 12 0 0 1 12 12zm-143.1-44a24 24 0 0 1-22.4-15.3C67.7 267.5 16 277.7 16 176a176 176 0 0 1 352 0c0 101.7-51.7 91.5-90.5 192.7A24 24 0 0 1 255 384H129zM112 176a80 80 0 0 1 80-80 16 16 0 0 0 0-32c-61.8 0-112 50.2-112 112a16 16 0 0 0 32 0z" />
    </svg>
  );
}

export default LightbulbIcon;
