import * as React from 'react';

function ShipIcon(props) {
  return (
    <svg {...props} viewBox="0 0 640 512">
      <path d="M496.6 372.6l70-70a32 32 0 0 0-12.8-53L512 236V96a32 32 0 0 0-32-32h-64V24a24 24 0 0 0-24-24H248a24 24 0 0 0-24 24v40h-64a32 32 0 0 0-32 32v140.1l-41.8 13.4a32 32 0 0 0-12.8 53.1l70 70C125.8 416.3 85.6 448 24 448a24 24 0 0 0-24 24v16a24 24 0 0 0 24 24c61 0 107.5-20.6 143.3-59.4A96.1 96.1 0 0 0 256 512h128c40 0 74.3-24.6 88.7-59.4C508.5 491.4 555 512 616 512a24 24 0 0 0 24-24v-16a24 24 0 0 0-24-24c-60.8 0-101.5-31-119.4-75.4zM192 128h256v87.5l-118.2-38a32 32 0 0 0-19.6 0l-118.2 38V128z" />
    </svg>
  );
}

export default ShipIcon;
