import * as React from 'react';

function ExclamationTriangleIcon(props) {
  return (
    <svg {...props} viewBox="0 0 576 512">
      <path d="M248.7 204.7l6.6 112a12 12 0 0 0 12 11.3h41.4a12 12 0 0 0 12-11.3l6.6-112a12 12 0 0 0-12-12.7h-54.6a12 12 0 0 0-12 12.7zM330 384a42 42 0 1 1-84 0 42 42 0 0 1 84 0zm-.4-360a48 48 0 0 0-83.2 0L6.4 440a48 48 0 0 0 41.7 72h479.8a48 48 0 0 0 41.6-72l-240-416zM53.2 455L282.8 57c2.3-4 8-4 10.4 0l229.6 398a6 6 0 0 1-5.2 9H58.4a6 6 0 0 1-5.2-9z" />
    </svg>
  );
}

export default ExclamationTriangleIcon;
