import * as React from 'react';
import * as PropTypes from 'prop-types';

import './style.css';

LoadingSpinner.propTypes = {
  /** CSS class to add to the component's main element. */
  className: PropTypes.string
};

function LoadingSpinner(props) {
  let className = 'r-loading-spinner';

  if (props.className) {
    className += ` ${props.className}`;
  }

  return (
    <svg className={className} viewBox="25 25 50 50">
      <circle cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
    </svg>
  );
}

export default LoadingSpinner;
