import * as React from 'react';

function EnvelopeIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M464 64H48a48 48 0 0 0-48 48v288a48 48 0 0 0 48 48h416a48 48 0 0 0 48-48V112a48 48 0 0 0-48-48zm0 48v40.8a9970.3 9970.3 0 0 1-134.6 106.5c-16.8 13.2-50.2 45-73.4 44.7-23.2.4-56.6-31.5-73.4-44.7A9969.7 9969.7 0 0 1 48 152.8V112h416zM48 400V214.4c23 18.2 55.4 43.9 105 82.6 21.8 17.2 60 55.2 103 55 42.7.2 80.5-37.2 103-55 49.6-38.7 82-64.3 105-82.6V400H48z" />
    </svg>
  );
}

export default EnvelopeIcon;
