import * as React from 'react';

function ClipboardCheckIcon(props) {
  return (
    <svg {...props} viewBox="0 0 384 512">
      <path d="M336 64h-88.6a56 56 0 1 0-110.8 0H48a48 48 0 0 0-48 48v352a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V112a48 48 0 0 0-48-48zM192 32a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm160 432a16 16 0 0 1-16 16H48a16 16 0 0 1-16-16V112a16 16 0 0 1 16-16h48v20a12 12 0 0 0 12 12h168a12 12 0 0 0 12-12V96h48a16 16 0 0 1 16 16v352zm-58.9-236.4a12.1 12.1 0 0 0-17-.1L158.4 344.3 108 293.7a12 12 0 0 0-17 0l-8.5 8.5a12 12 0 0 0 0 17l67.4 67.6a12 12 0 0 0 17 0l134.8-133.7c4.7-4.7 4.7-12.3.1-17l-8.7-8.5z" />
    </svg>
  );
}

export default ClipboardCheckIcon;
