import * as React from 'react';

function CalculatorIcon(props) {
  return (
    <svg {...props} viewBox="0 0 384 512">
      <path d="M336 0H48A48 48 0 0 0 0 48v416a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V48a48 48 0 0 0-48-48zM48 32h288a16 16 0 0 1 16 16v80H32V48a16 16 0 0 1 16-16zm288 448H48a16 16 0 0 1-16-16V160h320v304a16 16 0 0 1-16 16zM128 204v40a12 12 0 0 1-12 12H76a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm96 0v40a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm96 0v40a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm-192 96v40a12 12 0 0 1-12 12H76a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm96 0v40a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm96 0v136a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12V300a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm-192 96v40a12 12 0 0 1-12 12H76a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12zm96 0v40a12 12 0 0 1-12 12h-40a12 12 0 0 1-12-12v-40a12 12 0 0 1 12-12h40a12 12 0 0 1 12 12z" />
    </svg>
  );
}

export default CalculatorIcon;
