import * as React from 'react';

function EllipsisVIcon(props) {
  return (
    <svg {...props} viewBox="0 0 192 512">
      <path d="M96 184a72 72 0 1 1 0 144 72 72 0 0 1 0-144zM24 80a72 72 0 1 0 144 0 72 72 0 0 0-144 0zm0 352a72 72 0 1 0 144 0 72 72 0 0 0-144 0z" />
    </svg>
  );
}

export default EllipsisVIcon;
