import * as React from 'react';

function MicrophoneAltSlashIcon(props) {
  return (
    <svg {...props} viewBox="0 0 640 512">
      <path d="M634 471L36 3.5A16 16 0 0 0 13.5 6l-10 12.5A16 16 0 0 0 6 41l598 467.5a16 16 0 0 0 22.5-2.5l10-12.5A16 16 0 0 0 634 471zM496 256v-48a16 16 0 0 0-16-16h-16a16 16 0 0 0-16 16v48c0 2.8-.7 5.3-.9 8l43.2 33.8c3.4-13.5 5.7-27.3 5.7-41.8zm-96 208h-56v-33.8c20.7-2.8 40.1-9.4 57.9-18.8l-43.1-33.7c-16 5.2-33.5 7.5-51.6 5.7C240.7 376.9 192 317 192 250.3v-3l-48-37.5v38.4c0 89.6 64 169.5 152 181.7V464h-56a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h160a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16zM272 96a48 48 0 0 1 96 0h-40a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h40v32h-40c-3.4 0-5.8 2.4-7 5.4l34 26.6h13v10.1l48 37.6V96a95.7 95.7 0 1 0-191.4-6l47.4 37V96z" />
    </svg>
  );
}

export default MicrophoneAltSlashIcon;
