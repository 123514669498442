import * as React from 'react';

function SyncAltIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M483.5 28.5l-52.1 52.2a248 248 0 0 0-423 162.8A12 12 0 0 0 20.2 256h28a12 12 0 0 0 12-11.3 195.9 195.9 0 0 1 334.3-127.3l-54.1 54.1A12 12 0 0 0 349 192h143a12 12 0 0 0 12-12V37a12 12 0 0 0-20.5-8.5zm8.2 227.5h-28a12 12 0 0 0-12 11.3 195.9 195.9 0 0 1-334.3 127.3l54.1-54.1A12 12 0 0 0 163 320H20a12 12 0 0 0-12 12v143a12 12 0 0 0 20.5 8.5l52.2-52.1a248 248 0 0 0 423-162.8 12 12 0 0 0-12-12.6z" />
    </svg>
  );
}

export default SyncAltIcon;
