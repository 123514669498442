import * as React from 'react';

function ContainerStorageIcon(props) {
  return (
    <svg {...props} viewBox="0 0 640 512">
      <path d="M640 64V48a16 16 0 0 0-16-16H16A16 16 0 0 0 0 48v16a16 16 0 0 0 16 16v352a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h608a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16V80a16 16 0 0 0 16-16zm-64 368H64V80h512v352zm-440-48h32a8 8 0 0 0 8-8V136a8 8 0 0 0-8-8h-32a8 8 0 0 0-8 8v240a8 8 0 0 0 8 8zm224 0h32a8 8 0 0 0 8-8V136a8 8 0 0 0-8-8h-32a8 8 0 0 0-8 8v240a8 8 0 0 0 8 8zm112 0h32a8 8 0 0 0 8-8V136a8 8 0 0 0-8-8h-32a8 8 0 0 0-8 8v240a8 8 0 0 0 8 8zm-224 0h32a8 8 0 0 0 8-8V136a8 8 0 0 0-8-8h-32a8 8 0 0 0-8 8v240a8 8 0 0 0 8 8z" />
    </svg>
  );
}

export default ContainerStorageIcon;
