import * as React from 'react';

function KeyboardIcon(props) {
  return (
    <svg {...props} viewBox="0 0 576 512">
      <path d="M528 64H48a48 48 0 0 0-48 48v288a48 48 0 0 0 48 48h480a48 48 0 0 0 48-48V112a48 48 0 0 0-48-48zm8 336a8 8 0 0 1-8 8H48a8 8 0 0 1-8-8V112a8 8 0 0 1 8-8h480a8 8 0 0 1 8 8v288zM170 270v-28a12 12 0 0 0-12-12h-28a12 12 0 0 0-12 12v28a12 12 0 0 0 12 12h28a12 12 0 0 0 12-12zm96 0v-28a12 12 0 0 0-12-12h-28a12 12 0 0 0-12 12v28a12 12 0 0 0 12 12h28a12 12 0 0 0 12-12zm96 0v-28a12 12 0 0 0-12-12h-28a12 12 0 0 0-12 12v28a12 12 0 0 0 12 12h28a12 12 0 0 0 12-12zm96 0v-28a12 12 0 0 0-12-12h-28a12 12 0 0 0-12 12v28a12 12 0 0 0 12 12h28a12 12 0 0 0 12-12zm-336 82v-28a12 12 0 0 0-12-12H82a12 12 0 0 0-12 12v28a12 12 0 0 0 12 12h28a12 12 0 0 0 12-12zm384 0v-28a12 12 0 0 0-12-12h-28a12 12 0 0 0-12 12v28a12 12 0 0 0 12 12h28a12 12 0 0 0 12-12zM122 188v-28a12 12 0 0 0-12-12H82a12 12 0 0 0-12 12v28a12 12 0 0 0 12 12h28a12 12 0 0 0 12-12zm96 0v-28a12 12 0 0 0-12-12h-28a12 12 0 0 0-12 12v28a12 12 0 0 0 12 12h28a12 12 0 0 0 12-12zm96 0v-28a12 12 0 0 0-12-12h-28a12 12 0 0 0-12 12v28a12 12 0 0 0 12 12h28a12 12 0 0 0 12-12zm96 0v-28a12 12 0 0 0-12-12h-28a12 12 0 0 0-12 12v28a12 12 0 0 0 12 12h28a12 12 0 0 0 12-12zm96 0v-28a12 12 0 0 0-12-12h-28a12 12 0 0 0-12 12v28a12 12 0 0 0 12 12h28a12 12 0 0 0 12-12zm-98 158v-16a12 12 0 0 0-12-12H180a12 12 0 0 0-12 12v16a12 12 0 0 0 12 12h216a12 12 0 0 0 12-12z" />
    </svg>
  );
}

export default KeyboardIcon;
