import * as React from 'react';

function ParkingIcon(props) {
  return (
    <svg {...props} viewBox="0 0 448 512">
      <path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48zm0 394a6 6 0 0 1-6 6H54a6 6 0 0 1-6-6V86a6 6 0 0 1 6-6h340a6 6 0 0 1 6 6v340zM232 135.9h-80a16 16 0 0 0-16 16v216a16 16 0 0 0 16 16h16a16 16 0 0 0 16-16v-56h48a88 88 0 0 0 0-176zm0 128.1h-48v-80h48a40 40 0 1 1 0 80z" />
    </svg>
  );
}

export default ParkingIcon;
