import * as React from 'react';

function TruckContainerIcon(props) {
  return (
    <svg {...props} viewBox="0 0 640 512">
      <path d="M32 304h336a32 32 0 0 0 32-32V64a32 32 0 0 0-32-32H32A32 32 0 0 0 0 64v208a32 32 0 0 0 32 32zM48 80h304v176H48V80zm573.3 125.3l-58.5-58.5a64 64 0 0 0-45.3-18.7H464a32 32 0 0 0-32 32v176H32a32 32 0 0 0-32 32v27.8c0 40.8 28.7 78.1 69.1 83.5a79.7 79.7 0 0 0 74.9-31.7 79.9 79.9 0 0 0 142.5-63.5h163.2a80.7 80.7 0 0 0 2.8 42.4 80.2 80.2 0 1 0 154-42.5H624a16 16 0 0 0 16-16V250.5c0-17-6.7-33.2-18.7-45.2zM80 432c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm128 0c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm272-256h37.5c4.3 0 8.3 1.7 11.3 4.7l43.3 43.3H480v-48zm48 256c-17.6 0-32-14.4-32-32s14.4-32 32-32 32 14.4 32 32-14.4 32-32 32zm64-96h-16.4c-13.3-9.9-29.7-16-47.6-16s-34.2 6.1-47.6 16h-.4v-80h112v80zM136 112h-32a8 8 0 0 0-8 8v96a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-96a8 8 0 0 0-8-8zm160 0h-32a8 8 0 0 0-8 8v96a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-96a8 8 0 0 0-8-8zm-80 0h-32a8 8 0 0 0-8 8v96a8 8 0 0 0 8 8h32a8 8 0 0 0 8-8v-96a8 8 0 0 0-8-8z" />
    </svg>
  );
}

export default TruckContainerIcon;
