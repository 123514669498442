import * as React from 'react';

function CheckIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M435.8 83.5l-263 263L76.2 250a12 12 0 0 0-17 0L30.9 278a12 12 0 0 0 0 17l133.4 133.4a12 12 0 0 0 17 0L481 128.7a12 12 0 0 0 0-17l-28.3-28.2a12 12 0 0 0-17 0z" />
    </svg>
  );
}

export default CheckIcon;
