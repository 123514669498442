import * as React from 'react';

function BellIcon(props) {
  return (
    <svg {...props} viewBox="0 0 448 512">
      <path d="M224 512a64 64 0 0 0 64-64H160a64 64 0 0 0 64 64zm215.4-149.7c-19.3-20.8-55.5-52-55.5-154.3 0-77.7-54.5-139.9-128-155.2V32a32 32 0 1 0-63.9 0v20.8C118.6 68.1 64 130.3 64 208c0 102.3-36 133.5-55.4 154.3A32 32 0 0 0 32.1 416h383.8a32 32 0 0 0 23.5-53.7z" />
    </svg>
  );
}

export default BellIcon;
