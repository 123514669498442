import * as React from 'react';

function SortAmountDownIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M156.7 404.2l-68 72a12 12 0 0 1-17.4 0l-68-72A12 12 0 0 1 12 384h44V44a12 12 0 0 1 12-12h24a12 12 0 0 1 12 12v340h44a12 12 0 0 1 8.7 20.2zM236 136h264a12 12 0 0 0 12-12v-24a12 12 0 0 0-12-12H236a12 12 0 0 0-12 12v24a12 12 0 0 0 12 12zm-12 84v-24a12 12 0 0 1 12-12h200a12 12 0 0 1 12 12v24a12 12 0 0 1-12 12H236a12 12 0 0 1-12-12zm0 192v-24a12 12 0 0 1 12-12h72a12 12 0 0 1 12 12v24a12 12 0 0 1-12 12h-72a12 12 0 0 1-12-12zm0-96v-24a12 12 0 0 1 12-12h136a12 12 0 0 1 12 12v24a12 12 0 0 1-12 12H236a12 12 0 0 1-12-12z" />
    </svg>
  );
}

export default SortAmountDownIcon;
