import * as React from 'react';

function LinkIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M301.1 394.7L222 473.9A130 130 0 1 1 38.1 290l79.2-79.2a132.8 132.8 0 0 1 3.5-3.5 12 12 0 0 1 20.2 8.3c.2 4.8.6 9.6 1.3 14.4a12 12 0 0 1-3.4 10.1l-75.3 75.3a94 94 0 1 0 132.9 133l79.2-79.3.4-.3a94.1 94.1 0 0 0-28-151.7 12 12 0 0 1-7.1-11.7 62.2 62.2 0 0 1 2.8-15.2 12 12 0 0 1 16-7.3 130.2 130.2 0 0 1 41.4 211.7zM211 301.1a129.2 129.2 0 0 0 41.4 28 12 12 0 0 0 16-7.4 62.2 62.2 0 0 0 2.7-15.2 12 12 0 0 0-7.1-11.7 94.1 94.1 0 0 1-28-151.7l.4-.3 79.2-79.2a94 94 0 1 1 133 132.9L373 271.8a12 12 0 0 0-3.4 10 163.2 163.2 0 0 1 1.3 14.5 12 12 0 0 0 20.2 8.2 131.3 131.3 0 0 0 3.5-3.4l79.2-79.2A130 130 0 1 0 290 38.1l-79.2 79.2a130.2 130.2 0 0 0 0 183.8z" />
    </svg>
  );
}

export default LinkIcon;
