import * as React from 'react';

function TrashAltIcon(props) {
  return (
    <svg {...props} viewBox="0 0 448 512">
      <path d="M192 188v216a12 12 0 0 1-12 12h-24a12 12 0 0 1-12-12V188a12 12 0 0 1 12-12h24a12 12 0 0 1 12 12zm100-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12zm132-96a24 24 0 0 1 24 24v12a12 12 0 0 1-12 12h-20v336a48 48 0 0 1-48 48H80a48 48 0 0 1-48-48V128H12a12 12 0 0 1-12-12v-12a24 24 0 0 1 24-24h74.4l34-56.7A48 48 0 0 1 173.6 0h100.8a48 48 0 0 1 41.2 23.3l34 56.7H424zm-269.6 0h139.2l-17.4-29a6 6 0 0 0-5.2-3h-94a6 6 0 0 0-5.2 3l-17.4 29zM368 128H80v330a6 6 0 0 0 6 6h276a6 6 0 0 0 6-6V128z" />
    </svg>
  );
}

export default TrashAltIcon;
