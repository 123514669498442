import * as React from 'react';

function PenIcon(props) {
  return (
    <svg {...props} viewBox="0 0 512 512">
      <path d="M493.3 56.3l-37.6-37.5a63.8 63.8 0 0 0-90.5 0l-74.4 74.4L256 128 12.8 371 .1 485.3A24 24 0 0 0 26.6 512l114.2-12.7L384 256l34.8-34.7 74.4-74.5a64 64 0 0 0 0-90.5zm-374.6 397l-67.5 7.5 7.5-67.6 231.2-231.3 31-31 60.2 60.1-31 31-231.4 231.4zm340.6-340.5L415 157.1 355 97l44.3-44.3a16 16 0 0 1 22.6 0l37.5 37.5a16 16 0 0 1 0 22.6z" />
    </svg>
  );
}

export default PenIcon;
