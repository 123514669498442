import * as React from 'react';

function SwimmingPoolIcon(props) {
  return (
    <svg {...props} viewBox="0 0 640 512">
      <path d="M624 432h-16c-26 0-45.8-8.4-56-17.9A47.4 47.4 0 0 0 520 400h-16.3a47.4 47.4 0 0 0-31.7 14.1c-10.3 9.5-30 17.9-56.1 17.9s-45.8-8.4-56-17.9A47.4 47.4 0 0 0 328 400h-16.3a47.4 47.4 0 0 0-31.7 14.1c-10.3 9.5-30 17.9-56.1 17.9s-45.8-8.4-56-17.9A47.4 47.4 0 0 0 136 400h-16.3a47.4 47.4 0 0 0-31.7 14.1C77.8 423.6 58 432 32 432H16a16 16 0 0 0-16 16v16a16 16 0 0 0 16 16h16c38.6 0 72.7-12.2 96-31.8 23.3 19.6 57.4 31.8 96 31.8s72.7-12.2 96-31.8c23.3 19.6 57.4 31.8 96 31.8s72.7-12.2 96-31.8c23.3 19.6 57.4 31.8 96 31.8h16a16 16 0 0 0 16-16v-16a16 16 0 0 0-16-16zm-408-32.6V288h208v111.4c14.2-1.5 23.4-6 26.4-8.8a90 90 0 0 1 21.6-14.7V120a40 40 0 0 1 80 0v24a16 16 0 0 0 16 16h16a16 16 0 0 0 16-16v-24a88 88 0 0 0-176 0v120H216V120a40 40 0 0 1 80 0v24a16 16 0 0 0 16 16h16a16 16 0 0 0 16-16v-24a88 88 0 0 0-176 0v255.9a90 90 0 0 1 21.6 14.7c3 2.7 12.2 7.3 26.4 8.8z" />
    </svg>
  );
}

export default SwimmingPoolIcon;
